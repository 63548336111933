import React from 'react'

const Footer = props => (
  <footer id="footer">
    <section>
      <h2>John Kavanagh</h2>
      <p>
        <a href="https://johnkavanagh.co.uk">John Kavanagh</a> is a
        highly-experienced and talented freelance web developer based in
        Brighton, UK. He has two decades of development experience, having
        developed{' '}
        <a href="https://johnkavanagh.co.uk/projects/">
          websites, applications, and software
        </a>{' '}
        for hundreds of clients and millions of users.
      </p>
      <ul className="actions">
        <li>
          <a href="https://johnkavanagh.co.uk/about/" className="button">
            Find out more
          </a>
        </li>
      </ul>
    </section>
    <section>
      <h2>&nbsp;</h2>
      <dl className="alt">
        <dt>Address</dt>
        <dd>
          <address>Hollingbury Crescent &bull; Brighton &bull; BN1 7HD</address>
        </dd>
        <dt>Web</dt>
        <dd>
          <a href="https://johnkavanagh.co.uk">johnkavanagh.co.uk</a>
        </dd>
        <dt>Email</dt>
        <dd>
          <a href="mailto:mail@johnkavanagh.co.uk">mail@johnkavanagh.co.uk</a>
        </dd>
      </dl>
    </section>
    <p className="copyright">
      &copy; {new Date().getFullYear()} All rights reserved.
    </p>
  </footer>
)

export default Footer
