import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const Nav = props => (
  <nav id="nav" className={props.sticky ? 'alt' : ''}>
    <Scrollspy
      items={[
        'intro',
        'section1',
        'section2',
        'section3',
        'section4',
        'section5',
        'conclusion',
        'getintouch',
      ]}
      currentClassName="is-active"
      offset={-300}
    >
      <li>
        <Scroll type="id" element="intro">
          <a href="#intro">Intro</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="section1">
          <a href="#section1">#1</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="section2">
          <a href="#section2">#2</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="section3">
          <a href="#section3">#3</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="section4">
          <a href="#section4">#4</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="section5">
          <a href="#section5">#5</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="conclusion">
          <a href="#conclusion">Conclusion</a>
        </Scroll>
      </li>
      <li>
        <Scroll type="id" element="getintouch">
          <a href="#getintouch">Get in touch</a>
        </Scroll>
      </li>
    </Scrollspy>
  </nav>
)

export default Nav
