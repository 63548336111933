import React from 'react'

import logo from '../assets/images/logo.svg'

const Header = props => (
  <header id="header" className="alt">
    <span className="logo">
      <img src={logo} alt="Brighton Freelance Web Developer" />
    </span>
    <h1>
      <a href="https://johnkavanagh.co.uk">
        Brighton
        <br />
        Freelance Developer
      </a>
    </h1>
    <p>Successful Freelance Web Development In Brighton, UK</p>
  </header>
)

export default Header
